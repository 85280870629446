import { createContext } from 'react';
import { PlanType, PLAN_DUO, PLAN_FAMILY, guessPlanType } from 'lib/plans';
import { getLocale, Locale } from 'lib/i18n';
import config from 'config.json';
import { StaticAsset } from 'lib/assets';
import { NextRouter } from 'next/router';
import { encode, ParsedUrlQuery } from 'querystring';
import { getFirstStringOrDefault } from './string';

const { cdnBaseUrl } = config;

type DuoLinkType =
  | 'HOME_PAGE'
  | 'LANDING_PAGE'
  | 'SETUP'
  | 'SETUP_SUCCESS'
  | 'HOME_HUB'
  | 'HOME_HUB_STANDALONE'
  | 'INVITE'
  | 'CHANGE_ADDRESS'
  | 'SECURE_ACCOUNT'
  | 'SUB_LANDING_PAGE'
  | 'SUB_CONFIRM_ACCOUNT'
  | 'SUB_CONFIRMATION'
  | 'SUB_JOIN_ADDRESS'
  | 'ACCOUNT_OVERVIEW'
  | 'ACCOUNT_SUBSCRIPTION'
  | 'WELCOME'
  | 'PREMIUM_LANDING_PAGE'
  | 'INVITE_MEMBERS' /* hosts experimental /ready route for Duo users */
  | 'LOGOUT'
  | 'REVERIFY'
  | 'REMOVE_MEMBER'
  | 'STANDALONE_MEMBER_DETAILS';

type FamilyLinkType =
  | DuoLinkType
  | 'REENTER_ADDRESS'
  | 'REVERIFY'
  | 'UPGRADE_PLAN_PAGE';

export type LinkType = DuoLinkType | FamilyLinkType;

type DuoSupportLinkType = 'SUPPORT_ARTICLE_PLAN';
type FamilySupportLinkType =
  | DuoSupportLinkType
  | 'SUPPORT_ARTICLE_EXPLICIT_CONTENT'
  | 'SUPPORT_ARTICLE_CHANGES_EXISTING_NEW_FAMILY'
  | 'SUPPORT_ARTICLE_FAMILY_VERIFICATION';

export type SupportLinkType = DuoSupportLinkType | FamilySupportLinkType;

const linksDuo: Record<DuoLinkType, string> = {
  HOME_PAGE: '/:locale/',
  LANDING_PAGE: '/:locale/duo/',
  WELCOME: '/:locale/duo/welcome/',
  SETUP: '/:locale/duo/setup/',
  SETUP_SUCCESS: '/:locale/account/duo/',
  INVITE_MEMBERS: '/:locale/duo/ready/',
  HOME_HUB: '/:locale/account/duo/',
  HOME_HUB_STANDALONE: '/:locale/duo/home-hub/',
  ACCOUNT_OVERVIEW: '/:locale/account/overview/',
  ACCOUNT_SUBSCRIPTION: '/:locale/account/overview/#your-plan',
  INVITE: '/:locale/account/duo/invite/',
  CHANGE_ADDRESS: '/:locale/duo/change-address/',
  SECURE_ACCOUNT: '/:locale/account/security/checkup',
  SUB_LANDING_PAGE: '/:locale/duo/invite/:token/',
  SUB_CONFIRM_ACCOUNT: '/:locale/duo/join/confirm/:token/',
  SUB_JOIN_ADDRESS: '/:locale/duo/join/address/:token/',
  SUB_CONFIRMATION: '/:locale/duo/join/confirmation/',
  REVERIFY: '/:locale/duo/reverify/:token/',
  PREMIUM_LANDING_PAGE: '/:locale/premium/',
  LOGOUT: '/:locale/logout/?forward_url=:forwardUrl',
  REMOVE_MEMBER: '/:locale/duo/home-hub/remove-member/:memberId',
  STANDALONE_MEMBER_DETAILS: '/:locale/account/duo/member/:memberId',
};

const linksFamily: Record<FamilyLinkType, string> = {
  HOME_PAGE: '/:locale/',
  LANDING_PAGE: '/:locale/family/',
  WELCOME: '/:locale/family/welcome/',
  SETUP: '/:locale/family/setup/',
  SETUP_SUCCESS: '/:locale/family/ready/',
  INVITE_MEMBERS: '/:locale/family/ready/',
  HOME_HUB: '/:locale/account/family/',
  HOME_HUB_STANDALONE: '/:locale/family/home-hub/',
  ACCOUNT_OVERVIEW: '/:locale/account/overview/',
  ACCOUNT_SUBSCRIPTION: '/:locale/account/overview/#your-plan',
  INVITE: '/:locale/account/family/invite/',
  CHANGE_ADDRESS: '/:locale/family/change-address/',
  REENTER_ADDRESS: '/:locale/family/reenter-address/',
  SECURE_ACCOUNT: '/:locale/account/security/checkup',
  SUB_LANDING_PAGE: '/:locale/family/join/invite/:token/',
  SUB_CONFIRM_ACCOUNT: '/:locale/family/join/confirm/:token/',
  SUB_JOIN_ADDRESS: '/:locale/family/join/address/:token/',
  SUB_CONFIRMATION: '/:locale/family/join/confirmation/',
  REVERIFY: '/:locale/family/reverify/:token/',
  PREMIUM_LANDING_PAGE: '/:locale/premium/',
  LOGOUT: '/:locale/logout/?forward_url=:forwardUrl',
  REMOVE_MEMBER: '/:locale/family/home-hub/remove-member/:memberId',
  STANDALONE_MEMBER_DETAILS: '/:locale/account/family/member/:memberId',
  UPGRADE_PLAN_PAGE: '/:locale/family/upgrade/',
};

const supportLinksDuo: Record<DuoSupportLinkType, string> = {
  SUPPORT_ARTICLE_PLAN: 'article/duo/',
};

const supportLinksFamily: Record<FamilySupportLinkType, string> = {
  SUPPORT_ARTICLE_PLAN: 'article/premium-family/',
  SUPPORT_ARTICLE_EXPLICIT_CONTENT: 'article/content-censor/',
  SUPPORT_ARTICLE_CHANGES_EXISTING_NEW_FAMILY: 'article/family-plan-changes/',
  SUPPORT_ARTICLE_FAMILY_VERIFICATION: 'article/premium-family-verification/',
};

// This link can be used to close the In-App Browser on mobile
export const MOBILE_CLOSE_ABSOLUTE_URL =
  'https://spotify.com/:locale/mobile/close';

export function processURLParams(
  urlTemplate: string,
  params: Record<string, string> = {},
) {
  return Object.keys(params).reduce(
    (url, paramKey) => url.replace(`:${paramKey}`, params[paramKey]),
    urlTemplate,
  );
}

const getDuoLink = (linkType: DuoLinkType) => linksDuo[linkType];
const getFamilyLink = (linkType: FamilyLinkType) => linksFamily[linkType];

export const buildUrlStrict = (
  planType: PlanType,
  linkType: LinkType,
  locale: Locale,
  params: Record<string, string> = {},
  hash: string = '',
) => {
  const urlWithParams = processURLParams(
    planType === PLAN_FAMILY
      ? getFamilyLink(linkType)
      : getDuoLink(linkType as DuoLinkType),
    { locale, ...params },
  );

  return `${urlWithParams}${hash ? `#${hash}` : ''}`;
};

export type BuildUrlProps = {
  match?: {
    params?: {
      locale?: Locale;
    };
  };
  planType?: PlanType;
};

/**
 * @deprecated Use buildUrlStrict when possible
 */
export default function buildUrl(
  props: BuildUrlProps,
  linkType: LinkType,
  params: Record<string, string> = {},
  maybePlanType = props.planType,
) {
  const locale = props?.match?.params?.locale ?? getLocale();
  const planType = maybePlanType ?? guessPlanType(window.location.pathname);
  return buildUrlStrict(planType, linkType, locale, params);
}

export const buildAbsoluteUrl = (
  props: BuildUrlProps,
  route: LinkType,
  params: Record<string, string> = {},
) => {
  const path = buildUrl(props, route, params);
  return `${window.location.origin}${path}`;
};

export const buildAbsoluteUrlStrict = (
  planType: PlanType,
  route: LinkType,
  locale: Locale,
  params: Record<string, string> = {},
) => {
  const path = buildUrlStrict(planType, route, locale, params);
  return `${window.location.origin}${path}`;
};

export const getWebPlayerUrl = () =>
  process.env.NEXT_PUBLIC_WEB_PLAYER_BASE_URL;

export const getAudiobookProductPageUrl = (userName: string) =>
  `https://audiobooks.spotify.com/listening-hours?username=${userName}`;

const getDuoSupportLink = (linkType: DuoSupportLinkType) =>
  supportLinksDuo[linkType];
const getFamilySupportLink = (linkType: FamilySupportLinkType) =>
  supportLinksFamily[linkType];

export const getSupportUrl = (
  supportLinkType: SupportLinkType,
  planType = PLAN_DUO,
) =>
  `${process.env.NEXT_PUBLIC_SUPPORT_BASE_URL}${
    planType === PLAN_FAMILY
      ? getFamilySupportLink(supportLinkType)
      : getDuoSupportLink(supportLinkType as DuoSupportLinkType)
  }`;

export const getKidsGooglePlayStoreUrl = () =>
  'https://play.google.com/store/apps/details?id=com.spotify.kids';
export const getKidsAppStoreUrl = () =>
  'https://apps.apple.com/app/Spotify-Kids/id1470209570';

export const getAudiobookAccessSupportUrl = () =>
  'https://support.spotify.com/article/audiobooks-access-plan/';

export const getPlanJoinSupportArticle = (planType: PlanType) =>
  planType === PLAN_FAMILY
    ? 'https://support.spotify.com/article/start-or-join-family-plan/'
    : 'https://support.spotify.com/article/start-or-join-duo-plan/';

export const getConcertsHubUrl = () => 'https://open.spotify.com/concerts';

export const generateFullPath = (assetPath: StaticAsset) => {
  const isProduction = process.env.NODE_ENV === 'production';
  const isReviewDeployment = process.env.IS_SLINGSHOT === 'true';
  if (isProduction && !isReviewDeployment) {
    return `${cdnBaseUrl}/_next/static/public${assetPath}`;
  }
  return assetPath;
};

export const removeQueryParam = ({
  param,
  router,
}: {
  param: string;
  router: NextRouter;
}) => {
  const { pathname, query } = router;
  const encodedQuery = encode(query);
  const params = new URLSearchParams(encodedQuery);
  params.delete(param);
  router.replace({ pathname, query: params.toString() }, undefined, {
    shallow: true,
  });
};

export type AllowlistedQueryParams = {
  member?: string;
  view?: 'inApp';
};

export const QueryParamsContext = createContext<AllowlistedQueryParams>(
  {} as AllowlistedQueryParams,
);

export const getAllowListedQueryParams = (
  query: ParsedUrlQuery,
): AllowlistedQueryParams => {
  return {
    member: getFirstStringOrDefault(query.member, ''),
    view: query.view === 'inApp' ? 'inApp' : undefined,
  };
};
