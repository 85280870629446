import React from 'react';
import NextApp, { AppContext } from 'next/app';
import Head from 'next/head';
import { GlobalStyles } from 'styles/GlobalStyles';
import '@spotify-internal/encore-web/css/fonts.css';
import '@spotify-internal/encore-web/css/encore-text-styles.css';
import { guessPlanType, PlanType } from 'lib/plans';
import { DuoProvider, FamilyProvider } from 'data/plan/withPlan';
import {
  getInterpolationFunction,
  getMainLanguage,
  getTranslationFunction,
  getTranslations,
  isRTLLanguage,
  Translations,
} from 'lib/i18n';
import { TranslationContext } from 'data/translation/context';
import { ErrorProvider } from 'data/error/context';
import { Locale } from 'lib/marketLocales';
import { logMissingTranslations, logPageView } from 'lib/semanticMetrics';
import '@spotify-internal/masthead-react/index.css';
import '@spotify-internal/encore-web/css/encore-light-theme.css';
import '@spotify-internal/encore-web/css/encore-premium-family-set.css';
import '@spotify-internal/encore-web/css/encore-premium-family-alternate-set.css';
import '@spotify-internal/encore-web/css/encore-premium-duo-set.css';
import '@spotify-internal/encore-web/css/encore-premium-duo-alternate-set.css';
import '@spotify-internal/encore-web/css/encore-premium-set.css';
import '@spotify-internal/encore-web/css/encore-premium-alternate-set.css';
import '@spotify-internal/encore-web/css/encore-premium-student-set.css';
import { track } from '@spotify-internal/track-js';
import { StyleSheetManager } from 'styled-components';
import { isRunningInCypress } from 'lib/env';
import { NextRouter } from 'next/router';
import {
  getIframeHostEnvironmentFromQueryParam,
  IframeHostEnvironment,
} from 'lib/iframe';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import CSRFContext from 'data/csrf/context';
import { createCSRFClient } from 'lib/csrf';
import {
  AllowlistedQueryParams,
  getAllowListedQueryParams,
  QueryParamsContext,
} from 'lib/url';
import { createRouteMetrics } from '@spotify-internal/next-router-metrics';
import { sendMetricNonAuth } from 'services/semanticMetrics';
import { getUserId } from 'lib/commonProps';
import rtlPlugin from 'stylis-plugin-rtl';
import { captureException } from 'services/sentry';

function getPlanProvider(planType: PlanType) {
  if (planType === 'duo') {
    return DuoProvider;
  }
  return FamilyProvider;
}

export type CommonPageProps = {
  translations: Translations;
  locale: Locale;
  isLoggedIn: boolean;
  iframeHostEnvironment: IframeHostEnvironment;
  queryParams: AllowlistedQueryParams;
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // Do not retry requests in Cypress by default
      retry: isRunningInCypress() ? false : 3,
    },
  },
});

class CustomApp extends NextApp<{
  translations: Translations;
  originalCopy: Translations;
  locale: Locale;
  iframeHostEnvironment: IframeHostEnvironment;
  csrfToken: string | undefined;
  isLoggedIn: boolean;
}> {
  router: NextRouter | null = null;

  static async getInitialProps(appContext: AppContext) {
    const locale = appContext.router.locale as Locale;
    const translations = await getTranslations(locale || 'us');
    const appProps = await NextApp.getInitialProps(appContext);
    let originalCopy: Translations = {};
    if (appContext.ctx.req) {
      originalCopy = (await import('../locales/originals/strings.json'))
        .default;
      delete originalCopy.smartling;
    }
    return {
      ...appProps,
      locale,
      translations,
      originalCopy,
      iframeHostEnvironment: getIframeHostEnvironmentFromQueryParam(
        appContext.router.query.e,
      ),
      csrfToken: appContext.ctx.req?.headers['x-csrf-token'],
      isLoggedIn: !!getUserId(appContext.ctx?.req?.headers ?? {}),
    };
  }
  handleRouteChange = () => {
    if (this.router !== null) {
      logPageView({
        route: this.router.pathname,
      });
    }
  };

  componentDidMount() {
    const { router, locale, iframeHostEnvironment } = this.props;
    this.router = router;
    const isNotRunningInTest = !isRunningInCypress();

    const consentBoxEnabled = iframeHostEnvironment === null;

    if (window !== undefined) {
      this.handleRouteChange();
    }

    if (isNotRunningInTest && consentBoxEnabled) {
      track({
        dataLayerPushInitial: true,
        googleTagManagerId: 'GTM-7BJJ',
        language: getMainLanguage(locale),
        market: locale,
        oneTrust: true,
      }).catch(() => {
        // Ignore errors coming from track.js package. We don't want to report issues we have no control over.
      });
      router.events.on('routeChangeComplete', this.handleRouteChange);
    }
  }

  componentWillUnmount() {
    const { router } = this.props;
    router.events.off('routeChangeComplete', this.handleRouteChange);
  }

  render() {
    const {
      Component,
      pageProps,
      router,
      locale,
      iframeHostEnvironment,
      csrfToken,
      isLoggedIn,
      originalCopy,
    } = this.props;

    const PlanProvider = getPlanProvider(guessPlanType(router.asPath));
    const stylisPlugins = [...(isRTLLanguage(locale) ? [rtlPlugin] : [])];
    const t = getTranslationFunction(
      () => this.props.translations,
      originalCopy,
      translationKey => {
        captureException('Missing translation key', {
          data: { translationKey, locale },
        });
        logMissingTranslations({
          translation: translationKey,
          locale,
        });
      },
    );
    const interpolate = getInterpolationFunction(
      () => this.props.translations,
      originalCopy,
      translationKey => {
        logMissingTranslations({
          translation: translationKey,
          locale,
        });
      },
    );
    const csrfClient = createCSRFClient(csrfToken);
    const queryParams = getAllowListedQueryParams(router.query);

    const pathname = router?.pathname ?? '';
    const isRunningInIframe = pathname.includes('home-hub');
    return (
      <>
        <Head>
          <title>Spotify</title>
          <meta name="googlebot" content="noindex" />
          <link rel="icon" href="https://www.scdn.co/i/_global/favicon.png" />
          <link
            rel="apple-touch-icon-precomposed"
            sizes="144x144"
            href="https://www.scdn.co/i/_global/touch-icon-144.png"
          />
          <link
            rel="apple-touch-icon-precomposed"
            sizes="114x114"
            href="https://www.scdn.co/i/_global/touch-icon-114.png"
          />
          <link
            rel="apple-touch-icon-precomposed"
            sizes="72x72"
            href="https://www.scdn.co/i/_global/touch-icon-72.png"
          />
          <link
            rel="apple-touch-icon-precomposed"
            href="https://www.scdn.co/i/_global/touch-icon-57.png"
          />
          {isRunningInIframe && <base target="_top" />}
        </Head>
        <GlobalStyles $darkTheme={isRunningInIframe} />

        <StyleSheetManager stylisPlugins={stylisPlugins}>
          <QueryClientProvider client={queryClient}>
            <CSRFContext.Provider value={{ csrfClient }}>
              <TranslationContext.Provider value={{ locale, t, interpolate }}>
                <PlanProvider>
                  <ErrorProvider>
                    <QueryParamsContext.Provider value={queryParams}>
                      <Component
                        isLoggedIn={isLoggedIn}
                        {...pageProps}
                        queryParams={queryParams}
                        locale={locale}
                        iframeHostEnvironment={iframeHostEnvironment}
                      />
                    </QueryParamsContext.Provider>
                  </ErrorProvider>
                </PlanProvider>
              </TranslationContext.Provider>
            </CSRFContext.Provider>
          </QueryClientProvider>
        </StyleSheetManager>
      </>
    );
  }
}
// @ts-ignore next-router-metrics expects NextApp with no custom props
export default createRouteMetrics(sendMetricNonAuth)(CustomApp);
