import {
  MetricTypes,
  sendMetricAuth,
  sendMetricNonAuth,
} from 'services/semanticMetrics';
import { Locale } from 'lib/i18n';
import { AddressContext } from 'lib/trackingEvents';

export { Timer } from 'services/semanticMetrics';

export const logEvent = (tags: Record<string, any>) =>
  sendMetricNonAuth({
    metric_type: MetricTypes.COUNTER,
    what: 'home-hub-event',
    value: 1,
    tags,
  });

export const logError = (tags: Record<string, any>) =>
  sendMetricNonAuth({
    metric_type: MetricTypes.COUNTER,
    what: 'home-hub-error',
    value: 1,
    tags,
  });

export const logTrackingError = (error: string, path: string, locale: Locale) =>
  sendMetricNonAuth({
    metric_type: MetricTypes.COUNTER,
    what: 'home-hub-error',
    value: 1,
    tags: {
      error,
      context: 'tracking',
      locale,
      path,
    },
  });

export const logInitialLength = (
  change: number,
  context: AddressContext,
  locale: Locale,
) =>
  sendMetricAuth({
    metric_type: MetricTypes.COUNTER,
    what: 'home-hub-address-input-initial-length-change',
    value: 1,
    tags: { change: change.toString(), context, locale },
  });

export const logPageView = (tags: { route: string }) =>
  sendMetricNonAuth({
    metric_type: MetricTypes.COUNTER,
    what: 'pageview',
    value: 1,
    tags,
  });

export const logMissingTranslations = (tags: {
  translation: string;
  locale: string;
}) =>
  sendMetricNonAuth({
    metric_type: MetricTypes.COUNTER,
    what: 'missingtranslation',
    value: 1,
    tags,
  });
